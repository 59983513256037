.vis-tooltip {
    position: absolute;
}

::-webkit-scrollbar {
    width: 0.4em;
    height: 0.4em;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
    webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: #686c6c
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #3b3b3b;
}